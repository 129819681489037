import API from "../../api/api";

/**
 * Helper function to call an API endpoint with GET method.
 * @param {string} endpoint - The API endpoint to be called.
 * @returns {Promise<object>} - The data returned from the API on success.
 * @throws {Error} - If the API call fails.
 */
const callApi = async (endpoint) => {
    console.log(`callApi: Initiating GET request to endpoint: ${endpoint}`);
    try {
        const response = await API.get(endpoint);
        console.log(`callApi: Response received from ${endpoint}`, response);

        if (response.status === 200) {
            console.log(`callApi: Successful response from ${endpoint}`, response?.data?.data);
            return response?.data?.data;
        } else {
            console.warn(`callApi: Unexpected status code ${response.status} from ${endpoint}`);
            return response?.data?.data;
        }
    } catch (error) {
        console.error(`callApi: Error while calling ${endpoint}`, error);
        throw error;
    }
};

/**
 * LoginService - Contains methods for handling authentication and login-related actions.
 */
const LoginService = {
    /**
     * Authenticate user and obtain access token.
     * @param {object} data - The login credentials (e.g., username and password).
     * @returns {Promise<object>} - The response data containing the access token.
     */
    authAccess: async (data) => {
        console.log("authAccess: Initiating login request with data", data);
        return API.post("auth/access", JSON.stringify(data))
            .then((response) => {
                console.log("authAccess: Login successful", response.data);
                return response.data;
            })
            .catch((error) => {
                console.error("authAccess: Login failed", error);
                return Promise.reject(error);
            });
    },

    /**
     * Verify 2FA OTP for user authentication.
     * @param {object} data - The OTP data to verify.
     * @returns {Promise<object>} - The response data after successful verification.
     */
    verify2faOtp: async (data) => {
        console.log("verify2faOtp: Verifying 2FA OTP with data", data);
        return API.post("auth/verify-twofa-otp", JSON.stringify(data))
            .then((response) => {
                console.log("verify2faOtp: OTP verification successful", response.data);
                return response.data;
            })
            .catch((error) => {
                console.error("verify2faOtp: OTP verification failed", error);
                return Promise.reject(error);
            });
    },

    /**
     * Log out the current user.
     * @returns {Promise<object>} - The response data after successful logout.
     */
    logout: async () => {
        console.log("logout: Logging out user.");
        return API.post("auth/logout")
            .then((response) => {
                console.log("logout: Logout successful", response.data);
                return response.data;
            })
            .catch((error) => {
                console.error("logout: Logout failed", error);
                return Promise.reject(error);
            });
    },

    /**
     * Initiate password recovery for the user.
     * @param {object} data - The user data for password recovery.
     * @returns {Promise<object>} - The response data after initiating recovery.
     */
    forgotPassword: async (data) => {
        console.log("forgotPassword: Initiating forgot password request with data", data);
        return API.post("auth/forgot-password", JSON.stringify(data))
            .then((response) => {
                console.log("forgotPassword: Request successful", response.data);
                return response.data;
            })
            .catch((error) => {
                console.error("forgotPassword: Request failed", error);
                return Promise.reject(error);
            });
    },

    /**
     * Verify the forgot password token.
     * @param {object} data - The token data for verification.
     * @returns {Promise<object>} - The response data after verification.
     */
    verifyForgotPassword: async (data) => {
        console.log("verifyForgotPassword: Verifying forgot password token with data", data);
        return API.post("auth/verify-forgot-password", data)
            .then((response) => {
                console.log("verifyForgotPassword: Verification successful", response);
                return response;
            })
            .catch((error) => {
                console.error("verifyForgotPassword: Verification failed", error);
                return Promise.reject(error);
            });
    },

    /**
     * Update the password after verifying the forgot password token.
     * @param {object} data - The new password data.
     * @returns {Promise<object>} - The response data after updating the password.
     */
    changeForgotPassword: async (data) => {
        console.log("changeForgotPassword: Updating password with data", data);
        return API.post("auth/update-password", data)
            .then((response) => {
                console.log("changeForgotPassword: Password update successful", response.data);
                return response.data;
            })
            .catch((error) => {
                console.error("changeForgotPassword: Password update failed", error);
                return Promise.reject(error);
            });
    },

    /**
     * Verify the email address of a user.
     * @param {string} username - Encrypted username.
     * @param {string} adminUsername - Encrypted admin username.
     * @returns {Promise<object>} - The response data after verification.
     */
    verifyEmail: async (username, adminUsername) => {
        console.log(`verifyEmail: Verifying email with username: ${username}, adminUsername: ${adminUsername}`);
        return API.post(
            `auth/email-verification?encryptedUsername=${username}&encryptedAdminUsername=${adminUsername}`
        )
        .then((response) => {
            console.log("verifyEmail: Email verification successful", response.data);
            return response.data;
        })
        .catch((error) => {
            console.error("verifyEmail: Email verification failed", error);
            return Promise.reject(error);
        });
    },

    /**
     * Retrieve the company logo.
     * @returns {Promise<object>} - The company logo data.
     */
    companyLogo: async () => {
        console.log("companyLogo: Fetching company logo.");
        return callApi("auth/get-company-logo")
            .then((response) => {
                console.log("companyLogo: Successfully retrieved company logo", response);
                return response;
            })
            .catch((error) => {
                console.error("companyLogo: Failed to retrieve company logo", error);
                return Promise.reject(error);
            });
    },
};

export default LoginService;
