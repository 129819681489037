// The API KEY is set in the .env file.
const API_URL_KEY = process.env.REACT_APP_API_URL_KEY;

// Verify that the API URL Key is set
if (!API_URL_KEY) {
    console.error("API URL Key is not set. Exiting...");
    throw new Error("API URL Key is not set. Exiting...");
}
console.warn(`API URL Key: ${API_URL_KEY}`);

const API_BASE_URL = process.env[API_URL_KEY];

// Verify that the API Base URL is set
if (!API_BASE_URL) {
    console.error("API Base URL is not set. Exiting...");
    throw new Error("API Base URL is not set. Exiting...");
}
console.warn(`API Base URL: ${API_BASE_URL}`);

const DEFAULT_KEY = '26eeeb4e-9af9-44b5-a8ef-dfef6b460850';
const PAYPAL_CLIENT_ID = "Ad077-Gx8stGcfWQYawBiQUc4UbKWIRIg_PK4lHL4pzHR-uOPMz2vCk_jQ8QU9pcEw0j8U3UYDrEUS75"

// Temporary quick fix for the stripe products
// Products definition - PRODUCT and PRICING IDs are from the Stripe Dashboard
// and are ENVIRONMENT SPECIFIC.

// Common product structure
const STRIPE_PRODUCTS_COMMON = {
    Gem: {
        packageId: 1,
        validity: 6,
        coverage: 9000,
        price: 350,
        description: "Gem coverage level protects your vehicle up to $9,000 of damage per incident, for 6 months from the date of activation.",
        coverageAmount: "$9,000",
        pricing: {
            installment: { amount: 62 },
            every6Month: { amount: 350 }
        }
    },
    Bronze: {
        packageId: 2,
        validity: 6,
        coverage: 15000,
        price: 500,
        description: "Bronze coverage level protects your vehicle up to $15,000 of damage per incident, for 6 months from the date of activation.",
        coverageAmount: "$15,000",
        pricing: {
            installment: { amount: 88 },
            every6Month: { amount: 500 }
        }
    },
    Silver: {
        packageId: 3,
        validity: 6,
        coverage: 25000,
        price: 1000,
        description: "Silver coverage level protects your vehicle up to $25,000 of damage per incident, for 6 months from the date of activation.",
        coverageAmount: "$25,000",
        pricing: {
            installment: { amount: 175 },
            every6Month: { amount: 1000 }
        }
    },
    Gold: {
        packageId: 4,
        validity: 6,
        coverage: 50000,
        price: 1500,
        description: "Gold coverage level protects your vehicle up to $50,000 of damage per incident, for 6 months from the date of activation.",
        coverageAmount: "$50,000",
        pricing: {
            installment: { amount: 263 },
            every6Month: { amount: 1500 }
        }
    },
    Diamond: {
        packageId: 5,
        validity: 6,
        coverage: 100000,
        price: 1800,
        description: "Diamond coverage level protects your vehicle up to $100,000 of damage per incident, for 6 months from the date of activation.",
        coverageAmount: "$100,000",
        pricing: {
            installment: { amount: 315 },
            every6Month: { amount: 1800 }
        }
    }
};

// Environment-specific Stripe IDs
const STRIPE_IDS = {
    production: {
        Gem: {
            id: "prod_RGX8paiZAc8sbW",
            pricing: {
                installment: "price_1QO046IrLQQUfNmcjWmPbx24",
                every6Month: "price_1QO046IrLQQUfNmchcuAlbbH"
            }
        },
        Bronze: {
            id: "prod_RGX8akUJods7pu",
            pricing: {
                installment: "price_1QO043IrLQQUfNmca4uPSzZQ",
                every6Month: "price_1QO043IrLQQUfNmchhECTQf5"
            }
        },
        Silver: {
            id: "prod_RGX88Ihd8JSiYA",
            pricing: {
                installment: "price_1QO040IrLQQUfNmcrJ97NCMr",
                every6Month: "price_1QO040IrLQQUfNmcc7gp1VsC"
            }
        },
        Gold: {
            id: "prod_RGX8sIWKLjKsZd",
            pricing: {
                installment: "price_1QO03yIrLQQUfNmc3nOxp6Ap",
                every6Month: "price_1QO03yIrLQQUfNmcT6H2j9Sl"
            }
        },
        Diamond: {
            id: "prod_RFE0CpmUKumgfd",
            pricing: {
                installment: "price_1QMjY1IrLQQUfNmcLzxLankW",
                every6Month: "price_1QMjY1IrLQQUfNmcIP0en6nz"
            }
        }
    },
    development: {
        Gem: {
            id: "prod_RBSA9GUHtmHyNN",
            pricing: {
                installment: "price_1QJ8MdIrLQQUfNmcoD2RDIKQ",
                every6Month: "price_1QJ5GKIrLQQUfNmcfj8XfoMu"
            }
        },
        Bronze: {
            id: "prod_RBSFsZ6atc63rH",
            pricing: {
                installment: "price_1QJ8LdIrLQQUfNmchzawChAk",
                every6Month: "price_1QJ5KXIrLQQUfNmcOrcoGxBB"
            }
        },
        Silver: {
            id: "prod_RBSF2VZksqybIr",
            pricing: {
                installment: "price_1QJ8KwIrLQQUfNmcU5h1DKhk",
                every6Month: "price_1QJ5LGIrLQQUfNmcOP3SSFlH"
            }
        },
        Gold: {
            id: "prod_RBSL1z3WFp1910",
            pricing: {
                installment: "price_1QJ5QNIrLQQUfNmceJZjwwWY",
                every6Month: "price_1QJ8IDIrLQQUfNmciWb6r1gK"
            }
        },
        Diamond: {
            id: "prod_RBSYt4LrOz7aNh",
            pricing: {
                installment: "price_1QJ8JPIrLQQUfNmc1R0lXRlS",
                every6Month: "price_1QJ5dbIrLQQUfNmcRbwwAjMd"
            }
        }
    }
};

// Set this to the current environment from `process.env.REACT_APP_NODE_ENV`
const STRIPE_ENV = process.env.REACT_APP_NODE_ENV;

// Verify that the STRIPE_ENV is set
if (!STRIPE_ENV) {
    console.error("Stripe environment is not set. Exiting...");
    throw new Error("Stripe environment is not set. Exiting...");
}
console.warn(`Stripe environment: ${STRIPE_ENV}`);

// Dynamically populate the product structure with environment-specific IDs
const STRIPE_PRODUCTS = Object.keys(STRIPE_PRODUCTS_COMMON).reduce((acc, key) => {
    acc[key] = {
        ...STRIPE_PRODUCTS_COMMON[key],
        id: STRIPE_IDS[STRIPE_ENV][key].id,
        pricing: {
            installment: {
                ...STRIPE_PRODUCTS_COMMON[key].pricing.installment,
                priceId: STRIPE_IDS[STRIPE_ENV][key].pricing.installment
            },
            every6Month: {
                ...STRIPE_PRODUCTS_COMMON[key].pricing.every6Month,
                priceId: STRIPE_IDS[STRIPE_ENV][key].pricing.every6Month
            }
        }
    };
    return acc;
}, {});
console.warn(`Stripe products: ${JSON.stringify(STRIPE_PRODUCTS)}`);

export { API_BASE_URL, DEFAULT_KEY, PAYPAL_CLIENT_ID, STRIPE_PRODUCTS };
