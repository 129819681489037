import axios from "axios";
import { API_BASE_URL, DEFAULT_KEY } from "../config/config";

// Log the base URL for debugging purposes
console.log(`Initializing Replica API with base URL: ${API_BASE_URL}`);

// Create an Axios instance with a predefined base URL.
const instance = axios.create({
  baseURL: API_BASE_URL, // Base URL for all API requests
});

// Add response interceptor to handle responses and errors globally.
instance.interceptors.response.use(
  /**
   * Success response interceptor.
   * @param {object} response - The successful Axios response object.
   * @returns {object} - The response object for further processing.
   */
  (response) => {
    console.log("Replica API Response Success:", response);

    if (response.status === 200) {
      return response; // Pass the response object to the calling code.
    }
  },
  /**
   * Error response interceptor.
   * Handles specific HTTP status codes (e.g., 401).
   * @param {object} error - The Axios error object.
   * @returns {Promise<object>} - A rejected Promise with the error object.
   */
  (error) => {
    console.error("Replica API Response Error:", error);

    if (error.response && error.response.status === 401) {
      console.warn("401 Unauthorized: Handling authentication error.");

      // Handle specific error code 1042 differently.
      if (error.response.data?.data?.data?.code === 1042) {
        console.log("401 Unauthorized: Code 1042 received. Returning response data.");
        return error.response.data.data;
      }

      // Clear local storage and redirect to login for other 401 cases.
      console.log("401 Unauthorized: Clearing localStorage and redirecting to login.");
      localStorage.clear();
      window.location.href = "/login"; // Redirect to the login page.

      return Promise.reject(error); // Reject the Promise with the error.
    } else {
      console.warn("Unhandled Error Response:", error.response);
      return error.response.data; // Return the response data for further handling.
    }
  }
);

// Add request interceptor to set default headers and handle specific cases.
instance.interceptors.request.use((config) => {
  console.log("Replica API Request Config:", config);

  // Set default headers for all requests.
  config.headers["Accept"] = "application/json"; // Accept JSON responses.
  config.headers["Content-Type"] = "application/json"; // Send requests as JSON.
  config.headers["api-key"] = DEFAULT_KEY; // Include the default API key.

  // Add query parameters for specific API calls unless excluded.
  if (
    !(config.url.search("replica-home")) || 
    !(config.url.search("replica-register-get"))
  ) {
    console.log("Adding referralId and hash parameters to the request.");
    config.params = {
      referralId: localStorage.getItem("referralId"), // Add referral ID from localStorage.
      hash: localStorage.getItem("hashKey"), // Add hash key from localStorage.
    };
  }

  return config; // Return the modified config for the request.
});

// Export the Axios instance as the Replica API object for use throughout the application.
const replicaAPI = instance;

export default replicaAPI;
