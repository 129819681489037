import axios from "axios";
import { API_BASE_URL, DEFAULT_KEY } from "../config/config";
import { toast } from "react-toastify";

// Log the base URL to the console for debugging
console.log(`Initializing API with base URL: ${API_BASE_URL}`);

// Create an Axios instance with a predefined base URL.
const instance = axios.create({
  baseURL: API_BASE_URL, // Base URL for all API requests
});

// Add response interceptor to handle responses and errors globally.
instance.interceptors.response.use(
  /**
   * Success response interceptor.
   * @param {object} response - The successful Axios response object.
   * @returns {object} - The response object for further processing.
   */
  (response) => {
    console.log("API Response Success:", response);

    if (response.status === 200) {
      return response; // Pass the response object to the calling code.
    }
  },
  /**
   * Error response interceptor.
   * Handles various HTTP error statuses (e.g., 401, 403, 500).
   * @param {object} error - The Axios error object.
   * @returns {Promise<object>} - A rejected Promise with the error object.
   */
  (error) => {
    console.error("API Response Error:", error);

    if (error.response && error.response.status === 401) {
      console.warn("401 Unauthorized: Clearing localStorage and redirecting to login.");
      // Check for specific error code 1042 to handle differently.
      if (error.response.data?.data?.data?.code === 1042) {
        localStorage.clear();
        window.location.href = "/login"; // Redirect to login page.
        return error.response.data.data; // Return error data for specific handling.
      }
      localStorage.clear();
      window.location.href = "/login"; // Redirect to login page for other 401 cases.
      return Promise.reject(error); // Reject the Promise with the error.
    } else if (error.response && error.response.status === 403) {
      console.warn("403 Forbidden: Handling restricted access.");
      // Show a toast notification for forbidden access with a description.
      if (error?.response?.data?.description) {
        toast.error(error?.response?.data?.description); // Display an error message.
      }
      localStorage.clear();
      window.location.href = "/login"; // Redirect to login page.
      return Promise.reject(error); // Reject the Promise with the error.
    } else if (error.response && error.response.status === 500) {
      console.error("500 Internal Server Error: No specific action defined.");
      // Placeholder for handling server errors (e.g., log to a service or notify admins).
      // localStorage.clear();
      // window.location.href = "/login"; // Uncomment if server errors should redirect.
    } else {
      console.warn("Unhandled Error Response:", error.response);
      return error.response.data; // Return the response data for further handling.
    }
  }
);

// Add request interceptor to set default headers before each request.
instance.interceptors.request.use((config) => {
  console.log("API Request Config:", config);

  // Set default headers for all requests.
  config.headers["Accept"] = "application/json"; // Accept JSON responses.
  config.headers["Content-Type"] = "application/json"; // Send requests as JSON.
  config.headers["api-key"] = DEFAULT_KEY; // Include the default API key.
  config.headers["access-token"] = localStorage.getItem("access-token") || ""; // Include the access token from localStorage.
  
  return config; // Return the modified config for the request.
});

// Export the Axios instance as the API object for use throughout the application.
const API = instance;

export default API;
